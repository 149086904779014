import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Modal from './Modal'; // Ensure the path is correct

const BranchOrdersV2 = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [notification, setNotification] = useState('');
  const [currentPageReceived, setCurrentPageReceived] = useState(1);
  const [currentPagePickedUp, setCurrentPagePickedUp] = useState(1);
  const [currentPageCanceled, setCurrentPageCanceled] = useState(1);
  const ITEMS_PER_PAGE = 10; // Define the number of items per page

  const branch = JSON.parse(Cookies.get('userData') || '{}').branch;

  const parseOrderDate = (orderNumber) => {
    const numberPart = orderNumber.replace('ORD-', '');
    const year = `20${numberPart.substring(0, 2)}`; // Assuming years are from 2000
    const month = numberPart.substring(2, 4);
    const day = numberPart.substring(4, 6);
    const hour = numberPart.substring(6, 8);
    const minutes = numberPart.substring(8, 10);
    const seconds = numberPart.substring(10, 12);
    
    // Create a date string in ISO format
    const dateString = `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
    return new Date(dateString);
  };

  useEffect(() => {
    const fetchBranchOrders = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_SERVER}/api/orders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        const filteredOrders = response.data.filter(order => order.branch === branch);
        const sortedOrders = filteredOrders.sort((a, b) => {
          return parseOrderDate(b.orderNumber) - parseOrderDate(a.orderNumber); // Sort in descending order
        });
    
        setOrders(sortedOrders);
      } catch (error) {
        console.error('Error fetching branch orders', error);
      }
    };

    fetchBranchOrders();
  }, [branch]);

  const branchAddresses = {
    main: 'Main Branch - Piy Margal',
    second: 'Second Branch - Honradez',
    third: 'Third Branch - G. Tuazon',
    fourth: 'Fourth Branch',
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatOrderDateTime = (orderNumber) => {
    const numberPart = orderNumber.replace('ORD-', '');
    const dateString = `20${numberPart.substring(0, 2)}-${numberPart.substring(2, 4)}-${numberPart.substring(4, 6)}T${numberPart.substring(6, 8)}:${numberPart.substring(8, 10)}:${numberPart.substring(10, 12)}`;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Invalid date' : date.toLocaleString();
  };

  const handleRowClick = (order) => {
    setSelectedOrder(order);
  };

  const handleCloseModal = () => setSelectedOrder(null);

  const groupItemsByProduct = (items) => {
    const grouped = {};
    items.forEach(item => {
      if (!item || !item.product) return; // Safeguard
      const productName = item.product.name;
      if (!grouped[productName]) {
        grouped[productName] = [];
      }
      grouped[productName].push(`${item.variant} (${item.quantity} pcs) - ₱${item.price}`);
    });
    return grouped;
  };

  const handleUpdateStatus = async (newStatus) => {
    if (!selectedOrder) return;

    try {
      const token = Cookies.get('token');
      await axios.put(`${process.env.REACT_APP_ADMIN_SERVER}/api/orders/${selectedOrder._id}/status`, { status: newStatus }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOrders(prevOrders => prevOrders.map(order =>
        order._id === selectedOrder._id ? { ...order, status: newStatus } : order
      ));

      setNotification(`Order #${selectedOrder.orderNumber} status updated to ${newStatus}`);
      setTimeout(() => setNotification(''), 3000);
      setSelectedOrder(null);
    } catch (error) {
      console.error('Error updating order status', error);
    }
  };

  const ordersByStatus = {
    'Order Received': orders.filter(order => order.status === 'Order Received'),
    'Preparing': orders.filter(order => order.status === 'Preparing'),
    'Ready for Pickup': orders.filter(order => order.status === 'Ready for Pickup'),
    'Picked Up': orders.filter(order => order.status === 'Picked Up'),
    'Canceled': orders.filter(order => order.status === 'Canceled'),
  };

  const getCurrentPageOrders = (status, currentPage) => {
    const sortedOrders = ordersByStatus[status];
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return sortedOrders.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const maxPageReceived = Math.ceil(ordersByStatus['Order Received'].length / ITEMS_PER_PAGE);
  const maxPagePickedUp = Math.ceil(ordersByStatus['Picked Up'].length / ITEMS_PER_PAGE);
  const maxPageCanceled = Math.ceil(ordersByStatus['Canceled'].length / ITEMS_PER_PAGE);

  const handlePageChange = (status, direction) => {
    if (status === 'Order Received') {
      setCurrentPageReceived(prev => Math.max(1, Math.min(prev + direction, maxPageReceived)));
    } else if (status === 'Picked Up') {
      setCurrentPagePickedUp(prev => Math.max(1, Math.min(prev + direction, maxPagePickedUp)));
    } else {
      setCurrentPageCanceled(prev => Math.max(1, Math.min(prev + direction, maxPageCanceled)));
    }
  };

  return (
    <div className="container mx-auto p-6">
      {notification && (
        <div className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-lg z-50">
          {notification}
        </div>
      )}

      <h2 className="text-center text-2xl text-blue-500 font-bold mb-4">Orders for {branchAddresses[branch] || capitalizeFirstLetter(branch)} Branch</h2>

      {/* Table for Order Received */}
      <div className="mt-10">
        <h3 className="text-2xl font-bold mb-4 text-center">PENDING ORDERS</h3>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Order Number</th>
              <th className="border border-gray-300 p-2">Customer Name</th>
              <th className="border border-gray-300 p-2">Total</th>
              <th className="border border-gray-300 p-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {getCurrentPageOrders('Order Received', currentPageReceived).map(order => (
              <tr key={order._id} onClick={() => handleRowClick(order)} className="cursor-pointer hover:bg-gray-100">
                <td className="border border-gray-300 p-2">{order.orderNumber}</td>
                <td className="border border-gray-300 p-2">{order.user.name}</td>
                <td className="border border-gray-300 p-2">₱{order.total}</td>
                <td className="border border-gray-300 p-2">{formatOrderDateTime(order.orderNumber)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
            onClick={() => handlePageChange('Order Received', -1)}
            disabled={currentPageReceived === 1}
          >
            Previous
          </button>
          <span>Page {currentPageReceived} of {maxPageReceived}</span>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded ml-2"
            onClick={() => handlePageChange('Order Received', 1)}
            disabled={currentPageReceived === maxPageReceived}
          >
            Next
          </button>
        </div>
      </div>


      <div className="mt-10">
        <h3 className="text-2xl font-bold mb-4 text-center">CANCELED ORDERS</h3>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Order Number</th>
              <th className="border border-gray-300 p-2">Customer Name</th>
              <th className="border border-gray-300 p-2">Total</th>
              <th className="border border-gray-300 p-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {getCurrentPageOrders('Canceled', currentPageCanceled).map(order => (
              <tr key={order._id} onClick={() => handleRowClick(order)} className="cursor-pointer hover:bg-gray-100">
                <td className="border border-gray-300 p-2">{order.orderNumber}</td>
                <td className="border border-gray-300 p-2">{order.user.name}</td>
                <td className="border border-gray-300 p-2">₱{order.total}</td>
                <td className="border border-gray-300 p-2">{formatOrderDateTime(order.orderNumber)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
            onClick={() => handlePageChange('Canceled', -1)}
            disabled={currentPageCanceled === 1}
          >
            Previous
          </button>
          <span>Page {currentPageCanceled} of {maxPageCanceled}</span>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded ml-2"
            onClick={() => handlePageChange('Canceled', 1)}
            disabled={currentPageCanceled === maxPageCanceled}
          >
            Next
          </button>
        </div>
      </div>

      {/* Table for Picked Up Orders */}
      <div className="mt-10">
        <h3 className="text-2xl font-bold mb-4 text-center">COMPLETE ORDERS</h3>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Order Number</th>
              <th className="border border-gray-300 p-2">Customer Name</th>
              <th className="border border-gray-300 p-2">Total</th>
              <th className="border border-gray-300 p-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {getCurrentPageOrders('Picked Up', currentPagePickedUp).map(order => (
              <tr key={order._id} onClick={() => handleRowClick(order)} className="cursor-pointer hover:bg-gray-100">
                <td className="border border-gray-300 p-2">{order.orderNumber}</td>
                <td className="border border-gray-300 p-2">{order.user.name}</td>
                <td className="border border-gray-300 p-2">₱{order.total}</td>
                <td className="border border-gray-300 p-2">{formatOrderDateTime(order.orderNumber)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
            onClick={() => handlePageChange('Picked Up', -1)}
            disabled={currentPagePickedUp === 1}
          >
            Previous
          </button>
          <span>Page {currentPagePickedUp} of {maxPagePickedUp}</span>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded ml-2"
            onClick={() => handlePageChange('Picked Up', 1)}
            disabled={currentPagePickedUp === maxPagePickedUp}
          >
            Next
          </button>
        </div>
      </div>

      {/* Table for Canceled Orders */}
    

      {/* Order Details Modal */}
      {selectedOrder && (
        <Modal onClose={handleCloseModal} style={{ width: '100%', maxWidth: '800px' }}>
          <div className="p-4">
            <h2 className="text-lg font-bold mb-4">Order Details</h2>
            <table className="min-w-full mb-4">
              <tbody>
                <tr>
                  <td className="font-bold">Order #:</td>
                  <td className="text-right">{selectedOrder.orderNumber}</td>
                </tr>
                <tr>
                  <td className="font-bold">Customer:</td>
                  <td className="text-right">{selectedOrder.user.name}</td>
                </tr>
                <tr>
                  <td className="font-bold">Contact:</td>
                  <td className="text-right">{selectedOrder.user.contact}</td>
                </tr>
                <tr>
                  <td className="font-bold">Payment Method:</td>
                  <td className="text-right">{capitalizeFirstLetter(selectedOrder.paymentMethod)}</td>
                </tr>
                <tr>
                  <td className="font-bold">Status:</td>
                  <td className="text-right">{selectedOrder.status}</td>
                </tr>
              </tbody>
            </table>

            <div>
              <h3 className="font-bold mb-2">Order Items</h3>
              {Object.keys(groupItemsByProduct(selectedOrder.items)).map(productName => (
                <div key={productName}>
                  <h4 className="font-semibold">{productName}</h4>
                  <ul>
                    {groupItemsByProduct(selectedOrder.items)[productName].map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            <div className="mt-4 flex justify-between">
              {selectedOrder.status !== 'Canceled' && (
                <div>
                  <button
                    className="px-4 py-2 bg-green-500 text-white rounded mr-2"
                    onClick={() => handleUpdateStatus('Picked Up')}
                  >
                    Mark as Picked Up
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded"
                    onClick={() => handleUpdateStatus('Canceled')}
                  >
                    Cancel Order
                  </button>
                </div>
              )}
              <button
                className="px-4 py-2 bg-gray-300 text-black rounded"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BranchOrdersV2;
